import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { Container, Layout } from 'layout';
import Typography from 'common/Typography';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsPageProps> = ({
  data: {
    ldsPage: { urls, seo, title, body, additionalText, businessId, versionId, platform },
  },
}) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_OT_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  return (
    <Layout {...{ urls, seo }} background="sauvignon">
      <Container gutter className="lds-page">
        {additionalText ? (
          <Typography as="p" size={{ base: 35, md: 45 }} className="lds-page__title">
            {additionalText}
          </Typography>
        ) : null}
        <Typography as="h1" size={{ base: 35, md: 45 }} className="lds-page__title">
          {title}
        </Typography>
        {!isCheckingLdsVersion && (
          <Typography
            dangerouslySetInnerHTML={updatedLdsContent ?? body}
            size={{ base: 15, md: 20 }}
            className="lds-page__description"
          />
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query LdsPageQuery($lang: String, $path: [String]) {
    ldsPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      title
      body
      additionalText
      businessId: businessID
      versionId
      platform
    }
  }
`;

export default LdsPage;
